<template>
  <div class="elv-layout-main-menu-user-avatar">
    <div class="elv-layout-main-menu-help" :style="{ 'padding-left': isOpenState ? '4px' : '0px' }">
      <SvgIcon name="feedback" width="16" height="16" />
      <span v-show="isOpenState" @click="onFeedbackQuestion">{{ t('button.feedback') }}</span>
    </div>
    <div class="elv-layout-main-menu-help" :style="{ 'padding-left': isOpenState ? '4px' : '0px' }">
      <SvgIcon name="notebook" width="16" height="16" />
      <span v-show="isOpenState" @click="onClickDocuments">{{ t('button.helpCenter') }}</span>
    </div>
    <div class="elv-layout-main-menu-language">
      <el-dropdown
        trigger="click"
        placement="bottom-end"
        :show-arrow="false"
        popper-class="elv-layout-main-menu__dropdown-popper"
        @visible-change="onVisibleChange"
      >
        <div>
          <div class="el-dropdown-language" :style="{ 'padding-left': isOpenState ? '4px' : '0px' }">
            <SvgIcon name="language" width="16" height="16" />
            <div v-show="isOpenState">
              <span class="">{{ language }}</span>
              <el-icon class="elv-layout-main-menu__dropdown-icon arrow" @click="onClickMore()">
                <SvgIcon name="menu-top" width="16" height="16" color="#B3BFCE" />
                <!-- :style="{ transform: languageVisible ? 'rotate(180deg)' : 'rotate(0deg)' }" -->
              </el-icon>
            </div>
          </div>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="elv-layout-main-menu__dropdown elv-language" size="large">
            <el-dropdown-item class="elv-layout-main-menu__dropdown--item" @click="translationLanguage('en')"
              >English</el-dropdown-item
            >
            <el-dropdown-item class="elv-layout-main-menu__dropdown--item" @click="translationLanguage('zh')"
              >简体中文</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div v-if="isLogin" class="elv-layout-main-menu-user-avatar-main__login">
      <VueBlockies
        style="border-radius: 50%"
        :seed="md5UserParams"
        :color="changeAvatarColor(md5UserParams)[0]"
        :bgcolor="changeAvatarColor(md5UserParams)[1]"
        :size="7"
        :scale="4"
        :alt="user?.name"
        spot-color="#666"
      />
      <el-dropdown
        trigger="click"
        placement="bottom-end"
        :show-arrow="false"
        popper-class="elv-layout-main-menu__dropdown-popper"
      >
        <div>
          <div v-if="isOpenState" class="el-dropdown-username">
            <span class="">{{ user?.name }}</span>
            <el-icon class="elv-layout-main-menu__dropdown-icon" @click="onClickMore()">
              <SvgIcon name="more-dian" width="16" height="16" />
            </el-icon>
          </div>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="elv-layout-main-menu__dropdown" size="large">
            <el-dropdown-item class="elv-layout-main-menu__dropdown--item" @click="onAccountSettings">
              <span class="elv-layout-main-menu__dropdown--item-text">{{ t('button.accountSettings') }}</span>
            </el-dropdown-item>
            <el-dropdown-item class="elv-layout-main-menu__dropdown--item" @click="onLogout">
              <span class="elv-layout-main-menu__dropdown--item-text">{{ t('button.signOut') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div
      v-if="!isLogin"
      class="elv-layout-main-menu-user-avatar-main__logout"
      @click="onLogin()"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    >
      <SvgIcon name="user-default" width="20" height="20" :fill="isHover ? '#1343BF' : '#333030'" />
      <span v-if="isOpenState" class="elv-layout-main-menu-user-avatar-main-user__login">{{
        t('button.loginButtonTextInDetail')
      }}</span>
    </div>
    <SettingDialog v-if="isShowAccountSettingDialog" ref="settingDialogRef" />
  </div>
</template>

<script setup lang="ts">
import md5 from 'js-md5'
import { useI18n } from 'vue-i18n'
import UserApi from '@/api/UserApi'
import VueBlockies from 'vue-blockies'
import { ElMessage } from 'element-plus'
import { avatarColor } from '@/config/index'
import { useGlobalStore } from '@/stores/modules/global'
import { useEntityStore } from '@/stores/modules/entity'
import { useProjectStore } from '@/stores/modules/project'
import SettingDialog from './AccountSettings/SettingDialog.vue'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'

const route = useRoute()
const router = useRouter()
const { t, locale } = useI18n()
const globalStore = useGlobalStore()
const entityStore = useEntityStore()
const projectStore = useProjectStore()
const menuGlobalStore = useMenuGlobalStore()
const userGlobalStore = useUserGlobalStore()
const { isOpenState } = storeToRefs(menuGlobalStore)
const { user, isLogin } = storeToRefs(userGlobalStore)

const isHover = ref(false)
const md5UserParams = ref()
const languageVisible = ref(false)
const isShowAccountSettingDialog = ref(false)
const settingDialogRef = useTemplateRef('settingDialogRef')

const language = computed(() => {
  return locale.value === 'en' ? 'English' : '简体中文'
})

const languageArrowRotate = computed(() => {
  return languageVisible.value ? 'rotate(0deg)' : 'rotate(180deg)'
})

/**
 * @description: 保存语言
 * @param {string} val 语言
 */
const translationLanguage = async (val: string) => {
  try {
    if (locale.value === val) return
    localStorage.setItem('language', val)
    locale.value = val
    await UserApi.saveUserAccountSetting({
      language: val === 'en' ? 'EN_US' : 'ZH_CN'
    })
    ElMessage.success(t('message.saveSuccess'))
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  }
}

/**
 * @description: 问题反馈
 */
const onFeedbackQuestion = () => {
  const email = user.value?.email || ''
  let projectName = ''
  let prefillEntityID = ''
  let feedBackUrl = `https://elvencom.feishu.cn/share/base/form/shrcnfh96S9NcvDb6pZRieEPegb?prefill_Contact+details+%E8%81%94%E7%B3%BB%E6%96%B9%E5%BC%8F=${email}&hide_entityID=1`

  if (route?.params?.entityId && entityStore.entityDetail?.projectId) {
    const projectDetail = globalStore.projectDetail(entityStore.entityDetail?.projectId)
    projectName = `${projectDetail?.name || ''}`
    prefillEntityID = `&prefill_entityID=${route?.params?.entityId}`
  } else if (route?.params?.projectId && projectStore.projectDetail?.projectId) {
    projectName = projectStore.projectDetail?.name || ''
  }

  if (projectName) {
    feedBackUrl = `${feedBackUrl}&prefill_Organisation+Name+%E7%BB%84%E7%BB%87%E5%90%8D%E7%A7%B0=${projectName}`
  }
  if (prefillEntityID) {
    feedBackUrl = `${feedBackUrl}${prefillEntityID}`
  }

  window.open(feedBackUrl, '_blank')
}

/**
 * @description: 修改头像颜色
 * @param {*} val
 */
const changeAvatarColor: any = (val: string) => {
  const lastStr = val.substr(val.length - 1, 1)
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(lastStr))) {
    return changeAvatarColor(val.substr(0, val.length - 1))
  }
  return avatarColor[lastStr]
}

/**
 * @description: 点击了帮助文档
 */
const onClickDocuments = () => {
  const docsUrl = locale.value === 'en' ? 'https://docs.elven.com/v3/v/english' : 'https://docs.elven.com/v3'
  window.open(docsUrl, '_blank')
}

/**
 * @description: 退出登录
 * @return {*}
 */
const onLogout = () => {
  userGlobalStore.logout()
  globalStore.$reset()
  router.replace({
    name: 'login'
  })
  window.location.reload()
}

/**
 * @description: 登录
 * @return {*}
 */
const onLogin = () => {
  router.push({
    name: 'login'
  })
  //   userGlobalStore.openLogin()
}

/**
 * @description: 点击更多按钮
 * @return {*}
 */
const onClickMore = () => {
  menuGlobalStore.changeMenuFixed(true)
}

/**
 * @description: 打开账户设置
 * @return {*}
 */
const onAccountSettings = () => {
  isShowAccountSettingDialog.value = true
  settingDialogRef.value?.onCheckAccountSettingsDialog()
}

/**
 * @description: 显示隐藏语言
 * @param {boolean} visible
 * @return {*}
 */
const onVisibleChange = (visible: boolean) => {
  languageVisible.value = visible
}

watchEffect(() => {
  if (isLogin.value) {
    const md5UserId = userGlobalStore.user?.userId.slice(0, 18)
    md5UserParams.value = md5(md5UserId)
  }
})
</script>
<style scoped lang="scss">
.elv-layout-main-menu-user-avatar {
  box-sizing: border-box;
  width: 100%;
  padding-top: 12px;

  .elv-layout-main-menu-help {
    margin-left: 16px;
    padding-left: 4px;
    height: 28px;
    display: flex;
    align-items: center;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    cursor: pointer;

    svg {
      fill: #b3bfce;
      margin-right: 8px;
    }

    &:hover {
      color: #1e2024;
    }
  }

  .elv-layout-main-menu-language {
    margin-bottom: 9px;
    margin-top: 6px;
    padding-left: 16px;
    cursor: pointer;

    .el-dropdown {
      width: fit-content;
      border-radius: 2px;
      display: flex;
      justify-items: center;
      align-items: center;
      height: 28px;
      padding: 0;
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #636b75;

      &:hover {
        background: #f9fafb;
        color: #1e2024;
      }
    }
  }

  .el-dropdown-language {
    display: flex;
    justify-items: center;
    align-items: center;

    > div {
      display: flex;
      justify-items: center;
      align-items: center;
    }

    span {
      margin-left: 8px;
      margin-right: 4px;
    }
  }

  .elv-layout-main-menu__dropdown-icon {
    position: relative;

    &.arrow {
      transition: all 0.3s;
      transform: v-bind(languageArrowRotate);
    }

    svg {
      fill: #b3bfce;
    }
  }

  .elv-layout-main-menu-user-avatar-main__login {
    margin-top: 9px;
    height: 52px;
    box-sizing: border-box;
    border-top: 1px solid $elv-color-E8ECF0;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    > img {
      width: 28px;
      height: 28px;
    }

    .el-dropdown-username {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-left: 8px;

      span {
        font-family: 'Plus Jakarta Sans';
        width: 82px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: $elv-theme-base-text-color;
      }
    }
  }

  .elv-layout-main-menu-user-avatar-main__logout {
    height: 52px;
    box-sizing: border-box;
    border-top: 1px solid $elv-color-E8ECF0;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    &:hover {
      .elv-layout-main-menu-user-avatar-main-user__login {
        color: $elv-color-1343BF;
      }
    }

    .elv-layout-main-menu-user-avatar-main-user__default {
      height: 20px;
      width: 20px;
    }

    .elv-layout-main-menu-user-avatar-main-user__login {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 13px;
      line-height: 13px;
      color: $elv-color-0E1420;
      margin-left: 12px;
    }
  }
}

.elv-layout-main-menu__dropdown--item-icon {
  width: 15px;
  text-align: center;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.elv-layout-main-menu__dropdown--item-text {
  width: 120px;
  height: 22px;
}

.elv-record-link {
  color: #636b75;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;

  &:hover {
    color: #1e2024;
  }
}
</style>
<style lang="scss">
.elv-layout-main-menu__dropdown-popper {
  overflow: hidden;
  inset: auto auto 45.2px 134.8px !important;

  .elv-layout-main-menu__dropdown {
    padding: 0;

    .el-avatar {
      --el-avatar-bg-color: #edf2ff;
    }
    /* 下拉选项 */
    .el-dropdown-menu__item {
      box-sizing: border-box;
      width: 150px;
      height: 32px;
      background: #ffffff;
      padding: 0px 8px;
      font-size: 12px;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      justify-content: flex-start;
      align-items: center;
    }

    .el-dropdown-menu__item:not(.is-disabled):focus {
      background-color: $elv-color-F9FBFE;
      color: $elv-color-0E1420;
    }

    &.elv-language {
      .el-dropdown-menu__item {
        width: 154px;
        color: #394048;
      }
    }
  }
}

.elv-layout-main-menu-user-avatar {
  /* 消除小三角 */
  .el-popper__arrow::before {
    width: 0 !important;
    height: 0 !important;
  }
}
</style>
