<template>
  <div ref="menuContentRef" class="elv-layout-main-menu-container">
    <el-scrollbar ref="menuScrollbarRef" :height="menuScrollbarHeight">
      <el-collapse ref="projectMenuRef" v-model="openCollapse">
        <!-- :class="{ 'is-basic': item.plan === 'BASIC' || item.plan === 'FREE' }" -->
        <el-collapse-item
          v-for="(item, index) in menuList"
          :key="item.projectId"
          :data-projectId="item.projectId"
          class="elv-layout-main-menu-level1"
          :name="item.menuName"
          :disabled="true"
        >
          <!-- 一级导航 -->
          <template #title>
            <div
              class="elv-layout-main-menu-title-content"
              @mouseenter="onMouseEnter(index)"
              @mouseleave="onMouseLeave()"
              @click="onClickMenu"
            >
              <div class="elv-layout-main-menu-title-content__icon">
                <SvgIcon
                  :fill="chooseProjectId === item.projectId ? '#2d62eb' : hoverIndex === index ? fillColor : '#b3bfce'"
                  :name="item.menuIcon"
                  width="16"
                  height="16"
                />
              </div>
              <div v-show="isOpenState" class="elv-layout-main-menu-title-content__title title">
                {{ formatLanguageContent(item.menuName) }}
              </div>
              <div
                v-show="isOpenState"
                class="elv-layout-main-menu-title-content-right__icon"
                :class="{
                  'elv-layout-main-menu-title-content-right__icon--active': openCollapse.includes(item.menuName)
                }"
                @click="onCheckFirstLevelMenu(item.menuName)"
              >
                <SvgIcon
                  name="menu-top"
                  :fill="hoverIndex === index ? fillColor : '#646670'"
                  width="14"
                  height="14"
                ></SvgIcon>
              </div>
            </div>
          </template>
          <!-- 二级导航 -->
          <template v-if="isShowDisableDefaultMenu(item)">
            <div class="elv-layout-main-menu-nav-box">
              <ul>
                <li
                  v-for="(navItem, i) in freeProjectFeatureModuleList"
                  :key="index + '-' + i"
                  @click="onJumpFreeProjectPage(item)"
                >
                  <MenuMainItem :navItem="navItem" :isDisabled="true" />
                </li>
              </ul>
            </div>
          </template>

          <!-- 二级导航 -->
          <!-- <el-collapse v-else v-model="openCollapseSecond"> -->
          <el-collapse v-else v-model="openCollapseSecond">
            <template v-if="item.children.length">
              <el-collapse-item
                v-for="(entityData, entityIndex) in item.children"
                :key="entityData.entityId"
                class="elv-layout-main-menu-children"
                :name="entityData.menuName"
                :disabled="true"
                :data-entityId="entityData.entityId"
                :data-projectId="item.projectId"
              >
                <template #title>
                  <div
                    class="elv-layout-main-menu-children-title-content"
                    @mouseenter="onMouseEnter(entityIndex)"
                    @mouseleave="onMouseLeave()"
                    @click="onClickMenu"
                  >
                    <div
                      v-show="isOpenState"
                      class="elv-layout-main-menu-children-title-content__title title"
                      @click="onJumpDashboard(entityData.entityId)"
                    >
                      {{ formatLanguageContent(entityData.menuName) }}
                    </div>
                    <div
                      v-show="isOpenState"
                      class="elv-layout-main-menu-title-content-right__icon"
                      :class="{
                        'elv-layout-main-menu-title-content-right__icon--active': openCollapseSecond.includes(
                          entityData.menuName
                        )
                      }"
                      @click="onCheckSecondLevelMenu(entityData.menuName)"
                    >
                      <SvgIcon
                        name="menu-top"
                        :fill="hoverIndex === index ? fillColor : '#646670'"
                        width="14"
                        height="14"
                      ></SvgIcon>
                    </div>
                  </div>
                </template>
                <!-- 三级导航 -->
                <div class="elv-layout-main-menu-children-nav-box">
                  <ul v-show="isOpenState">
                    <li v-for="(navItem, i) in entityData.children" :key="entityIndex + '-' + i">
                      <MenuMainItem :navItem="navItem" :isChoose="chooseId === navItem.id" @onNavChange="onNavChange" />
                    </li>
                  </ul>
                </div>
              </el-collapse-item>
            </template>
            <div
              v-if="isShowSettings(item)"
              class="elv-layout-menu-main-nav-box__settings"
              :data-projectId="item.projectId"
              :class="{
                'elv-layout-menu-main-nav-box__settings-choose':
                  chooseId === `${item.menuName}${item?.projectId}Settings`
              }"
              @click="
                onNavChange({
                  jumpUrl: `/project/${item?.projectId}/settings`,
                  id: `${item.menuName}${item?.projectId}Settings`,
                  soon: false
                })
              "
            >
              <div>{{ t('menus.settings') }}</div>
            </div>
          </el-collapse>
        </el-collapse-item>
        <div
          v-show="isOpenState"
          :class="[!menuList.length ? 'elv-layout-main-menu-nav-create' : 'elv-layout-main-menu-nav-created']"
          @click="onCreateProject"
        >
          <template v-if="menuList.length">
            <SvgIcon name="create-project" width="16" height="16" />
            {{ t('button.create') }}
          </template>
          <p v-else><span>👉</span>{{ t('report.startingFirstProject') }}</p>
        </div>
      </el-collapse>
    </el-scrollbar>
  </div>
  <ElvMessageBox
    ref="createdProjectMessageBoxRef"
    class="elv-message-created-free-project-box"
    :showCancelButton="true"
    :cancelButtonText="t('button.cancel')"
    :confirmButtonText="t('button.contactSales')"
    :title="t('title.freePlanLimitReached')"
    @onConfirmEvent="onConfirmContractSales"
    @onCancelEvent="onCancelCreatedProject"
  >
    <template #content>
      <span v-dompurify-html="t('message.freePlanReachedTipInfo')" class="elv-message-created-free-project-box-content">
      </span>
    </template>
  </ElvMessageBox>
</template>
<script lang="ts" setup>
import Sortable from 'sortablejs'
import userApi from '@/api/UserApi'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import { MenuItemType } from '#/MenuTypes'
import MenuMainItem from './MenuMainItem.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useGlobalStore } from '@/stores/modules/global'
import { isMobile, formatLanguageContent } from '@/lib/utils'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import { map, find, uniq, sortBy, filter, flatten } from 'lodash-es'
import { ProjectPermissionLevel, ProjectReviewStatus, ProjectSubscribeStatus } from '#/ProjectTypes'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const globalStore = useGlobalStore()
const entityStore = useEntityStore()
const userGlobalStore = useUserGlobalStore()
const menuGlobalStore = useMenuGlobalStore()
const { user } = storeToRefs(userGlobalStore)
const { menuList, menuChooseId, isOpenState } = storeToRefs(menuGlobalStore)

const chooseId = ref('')
const hoverIndex: any = ref(0)
const openCollapse = ref([''])
const chooseProjectId = ref('')
const fillColor = ref('#646670')
const menuScrollbarHeight = ref()
const openCollapseSecond = ref([''])
const chooseFatherMenuName = ref('')
const menuContentRef = useTemplateRef('menuContentRef')
const projectMenuRef = useTemplateRef('projectMenuRef')
const menuScrollbarRef = useTemplateRef('menuScrollbarRef')
const createdProjectMessageBoxRef = useTemplateRef('createdProjectMessageBoxRef')

const freeProjectFeatureModuleList = [
  { name: 'menus.dashboard' },
  { name: 'project.nav.accounts' },
  { name: 'menus.transactions' },
  { name: 'menus.valuation' },
  { name: 'menus.ledger' },
  { name: 'report.reconciliation' },
  { name: 'menus.reports' }
]

// eslint-disable-next-line no-unused-vars
const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

/**
 * 是否显示设置按钮
 */
const isShowSettings = computed(() => (item: any) => {
  const projectInfo = globalStore.projectDetail(item?.projectId)
  const unActiveFreeProject = !(
    projectInfo?.plan === ProjectPermissionLevel.FREE &&
    projectInfo?.projectReview?.status === ProjectReviewStatus.UNACTIVATION
  )
  return (
    (user.value?.userId === item?.userId || ['ADMIN', 'SUPER_ADMIN'].includes(item?.role || '')) &&
    isOpenState &&
    unActiveFreeProject
  )
})

/**
 * 显示禁用默认菜单
 */
const isShowDisableDefaultMenu = computed(() => (item: MenuItemType) => {
  const currentProject = globalStore.projectDetail(item?.projectId || '')
  return (
    (item.plan === ProjectPermissionLevel.FREE && !item.children?.length) ||
    currentProject?.projectReview?.status === ProjectReviewStatus.STOP ||
    currentProject?.subscribeStatus === ProjectSubscribeStatus.WAIT_PAYMENT ||
    ((currentProject?.plan === ProjectPermissionLevel.BASIC ||
      currentProject?.plan === ProjectPermissionLevel.STANDARD) &&
      !item.children?.length)
  )
})

/**
 * 免费项目菜单禁用状态下 跳转页面逻辑
 */
const onJumpFreeProjectPage = (item: any) => {
  const currentProject = globalStore.projectDetail(item.projectId)
  if (currentProject?.projectReview?.status === ProjectReviewStatus.UNACTIVATION) {
    router.push({ name: 'landing-activate' })
    return
  }
  if (currentProject?.subscribeStatus === ProjectSubscribeStatus.WAIT_PAYMENT) {
    router.push({ name: 'landing-subscription', query: { projectId: item?.projectId } })
    return
  }
  if (!currentProject?.entityList?.length) {
    router.push({ name: 'entity-settings', params: { projectId: currentProject?.projectId } })
    return
  }
  router.push({ name: 'project-settings', params: { projectId: item?.projectId } })
}

/**
 * @description: 改变导航
 * @param {*} val
 * @return {*}
 */
const onNavChange = (val: any) => {
  if (!val.soon && (val.jumpUrl !== route.path || route.name === 'project-settings')) {
    chooseId.value = val.id
    menuGlobalStore.changeMenuChooseId(val.id)
    router.push(val.jumpUrl)
    if (isMobile()) {
      menuGlobalStore.changeMenuOpen(false)
    }
  }
}

/**
 * @description: 展开菜单
 * @return {*}
 */
const onClickMenu = () => {
  if (isMobile()) {
    menuGlobalStore.changeMenuOpen(true)
  }
}

/**
 * @description: 鼠标移入菜单
 * @param {number} indexVal
 * @return {*}
 */
const onMouseEnter = (indexVal: number) => {
  fillColor.value = '#2d62eb'
  hoverIndex.value = indexVal
}

/**
 * @description: 鼠标移出菜单
 * @return {*}
 */
const onMouseLeave = () => {
  fillColor.value = '#646670'
  hoverIndex.value = null
}

/**
 * @description: 改变页面中nav的高度
 * @return {*}
 */
const changePageMenuWidth = () => {
  const allHeight = document.documentElement.clientHeight || document.body.clientHeight
  menuScrollbarHeight.value = allHeight - 225
  if (globalStore.showUpGradeInfo) {
    menuScrollbarHeight.value -= 31
  }
}

/**
 * @description: 创建项目
 * @return {*}
 */
const onCreateProject = () => {
  router.push({ name: 'landing-plan' })
}

/**
 * @description: 跳转dashboard
 * @param {*} entityId
 * @return {*}
 */
const onJumpDashboard = (entityId: string) => {
  if (entityId) {
    // TODO: 注释跳转到dashboard
    // router.push({ name: 'entity-dashboard', params: { entityId } })
  }
}

/**
 * @description: 展开一级菜单
 * @param {string} name
 * @return {*}
 */
const onCheckFirstLevelMenu = (name: string) => {
  if (openCollapse.value.includes(name)) {
    openCollapse.value = openCollapse.value.filter((item: any) => item !== name)
  } else {
    openCollapse.value.push(name)
  }
}

/**
 * @description: 展开二级菜单
 * @param {string} name
 * @return {*}
 */
const onCheckSecondLevelMenu = (name: string) => {
  if (openCollapseSecond.value.includes(name)) {
    openCollapseSecond.value = openCollapseSecond.value.filter((item: any) => item !== name)
  } else {
    openCollapseSecond.value.push(name)
  }
}

/**
 * @description: 添加展开菜单
 * @param {object} params
 * @param {string} params.type
 * @param {string} params.projectId
 * @param {string} params.name
 * @param {boolean} [params.isEdit]
 */
const onPushOpenCollapse = (params: any) => {
  openCollapse.value.push(params.name)
  openCollapseSecond.value.push(params.name)
  if (!params?.isEdit) {
    menuGlobalStore.changeMenuChooseId(`${params.name}${params.projectId}${params.entityId}Accounts`)
  }
}

emitter.on('onPushOpenCollapse', onPushOpenCollapse)

/**
 * @description: 初始化菜单排序
 * @return {*}
 */
const initSortable = () => {
  nextTick(() => {
    const firstLevelMenu = menuContentRef.value?.querySelector('.el-scrollbar__view .el-collapse')
    const secondaryMenuAll = projectMenuRef.value?.$el?.querySelectorAll('.el-collapse')
    Sortable.create(firstLevelMenu as HTMLElement, {
      animation: 300,
      handle: '.elv-layout-main-menu-level1.el-collapse-item .elv-layout-main-menu-title-content__title',
      draggable: '.elv-layout-main-menu-level1.el-collapse-item',
      onStart: () => {
        console.log(projectMenuRef.value?.activeNames)
      },
      onEnd: async ({ newIndex, oldIndex }) => {
        if (oldIndex !== newIndex) {
          try {
            let sortedProject: any = []
            if (userGlobalStore.navigationSort?.length) {
              sortedProject = sortBy(globalStore.projectList, (item) => {
                const sortOrderItem = find(userGlobalStore.navigationSort, { projectId: item.projectId })
                return sortOrderItem ? userGlobalStore.navigationSort.indexOf(sortOrderItem) : 999999
              })
            } else {
              sortedProject = globalStore.projectList
            }
            sortedProject.splice(Number(newIndex), 0, sortedProject.splice(Number(oldIndex), 1)[0])

            const navigationSort = sortedProject.map((item: any) => {
              return {
                projectId: item?.projectId,
                entityIds:
                  item?.entityIds && item?.entityIds?.length
                    ? item?.entityIds
                    : (item?.entityList?.map((i: any) => i.entityId) ?? [])
              }
            })
            await userApi.userNavigationSort(navigationSort)
            ElMessage.success(t('message.success'))
            userGlobalStore.navigationSort = navigationSort
          } catch (error: any) {
            userGlobalStore.userInit()
            ElMessage.error(error.message)
          }
        }
      }
    })
    secondaryMenuAll.forEach((element: any) => {
      Sortable.create(element, {
        animation: 300,
        handle: '.el-collapse-item.elv-layout-main-menu-children .elv-layout-main-menu-children-title-content__title',
        filter: '.elv-layout-menu-main-nav-box__settings',
        draggable: '.el-collapse-item.elv-layout-main-menu-children',
        onStart: () => {},
        onEnd: async (event: any) => {
          if (event?.oldIndex !== event?.newIndex) {
            try {
              let sortedProject: any = []
              if (userGlobalStore.navigationSort?.length) {
                sortedProject = sortBy(globalStore.projectList, (item) => {
                  const sortOrderItem = find(userGlobalStore.navigationSort, { projectId: item.projectId })
                  return sortOrderItem ? userGlobalStore.navigationSort.indexOf(sortOrderItem) : 999999
                })

                sortedProject.forEach((item: any) => {
                  const sortOrderItem: any = find(userGlobalStore.navigationSort, { projectId: item.projectId })
                  if (sortOrderItem) {
                    item.entityList = sortBy(item.entityList, (entity) => {
                      const sortOrderEntity = sortOrderItem.entityIds.indexOf(entity.entityId)
                      return sortOrderEntity !== -1 ? sortOrderEntity : 999999
                    })
                  }
                })
              } else {
                sortedProject = globalStore.projectList
              }
              const projectId = String(event?.item?.attributes?.['data-projectId']?.value)
              const projectIndex = sortedProject.findIndex((item: any) => item.projectId === projectId)
              const entityList =
                sortedProject[projectIndex]?.entityIds && sortedProject[projectIndex]?.entityIds?.length
                  ? sortedProject[projectIndex]?.entityIds
                  : (sortedProject[projectIndex]?.entityList?.map((i: any) => i.entityId) ?? [])
              entityList.splice(Number(event.newIndex), 0, entityList.splice(Number(event.oldIndex), 1)[0])
              sortedProject[projectIndex].entityIds = entityList

              const navigationSort = sortedProject.map((item: any) => {
                return {
                  projectId: item?.projectId,
                  entityIds:
                    item?.entityIds && item?.entityIds?.length
                      ? item?.entityIds
                      : (item?.entityList?.map((i: any) => i.entityId) ?? [])
                }
              })
              console.log(navigationSort)
              await userApi.userNavigationSort(navigationSort)
              ElMessage.success(t('message.success'))
              userGlobalStore.navigationSort = navigationSort
            } catch (error: any) {
              userGlobalStore.userInit()
              ElMessage.error(error.message)
            }
          }
        }
      })
    })
  })
}

/**
 * @description: 生成menuId
 * @param {*} entityData
 * @param {string} routeName
 * @return {string} menuId
 */
const generateMenuId = (entityData: any, routeName: string) => {
  let menuId = ''
  switch (true) {
    case routeName.indexOf('landing') !== -1:
      menuId = ''
      break
    case routeName.indexOf('accounts') !== -1:
      menuId = `${entityData.menuName}${entityData.projectId}${entityData.entityId}Accounts`
      break
    case routeName.indexOf('ledger') !== -1:
      menuId = `${entityData.menuName}${entityData.projectId}${entityData.entityId}Ledger`
      break
    case routeName.indexOf('transactions') !== -1:
      menuId = `${entityData.menuName}${entityData.projectId}${entityData.entityId}Transactions`
      break
    case routeName.indexOf('valuation') !== -1:
      menuId = `${entityData.menuName}${entityData.projectId}${entityData.entityId}Valuation`
      break
    case routeName === 'project-settings':
      menuId = `${entityData.menuName}${entityData.projectId}Settings`
      break
    case routeName.indexOf('cost-basis') !== -1:
      menuId = `${entityData.menuName}${entityData.projectId}${entityData.entityId}CostBasis`
      break
    case routeName.indexOf('reconciliation') !== -1:
      menuId = `${entityData.menuName}${entityData.projectId}${entityData.entityId}Reconciliation`
      break
    case routeName.indexOf('dashboard') !== -1:
      menuId = `${entityData.menuName}${entityData.projectId}${entityData.entityId}Dashboard`
      break
    default:
      menuId = `${entityData.menuName}${entityData.projectId}${entityData.entityId}Reports`
  }
  return menuId
}

/**
 * 取消创建项目
 */
const onCancelCreatedProject = () => {
  createdProjectMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * 联系销售
 */
const onConfirmContractSales = () => {
  window.open('mailto:sales@elven.com', '_blank')
}

watch(
  () => route.name,
  () => {
    const menuMainDataShowFlat = flatten(
      map(menuList.value, (item: any) => {
        return item.children
      })
    )
    const entityData = find(menuMainDataShowFlat, { entityId: route.params?.entityId as string })
    const routeName = String(route.name)
    let menuId = ''
    if (entityData) {
      menuId = generateMenuId(entityData, routeName)
      menuGlobalStore.changeMenuChooseId(menuId)
      chooseId.value = menuChooseId.value
    } else {
      menuGlobalStore.changeMenuChooseId('')
      chooseId.value = menuChooseId.value
    }
  }
)

watch(
  () => menuChooseId.value,
  () => {
    chooseId.value = menuChooseId.value
    const menuMainDataShowFlat = flatten(
      map(menuList.value, (item: any) => {
        return item.children
      })
    )
    const entityData = find(menuMainDataShowFlat, { entityId: route.params?.entityId as string })
    if (!menuChooseId.value) {
      const routeName = String(route.name)
      let menuId = ''
      if (entityData) {
        menuId = generateMenuId(entityData, routeName)
      } else if (routeName === 'project-settings') {
        const menuData: any = find(menuList.value, { projectId: String(route.params?.projectId) })
        menuId = `${menuData?.menuName}${menuData?.projectId}Settings`
      } else {
        menuId = ''
      }
      menuGlobalStore.changeMenuChooseId(menuId)
      chooseId.value = menuChooseId.value
    }
    const currentMenuData = filter(menuMainDataShowFlat, (item: any) => {
      return item.id === chooseId.value
    })[0]
    chooseProjectId.value = currentMenuData?.projectId ?? entityData?.projectId
    chooseFatherMenuName.value = currentMenuData?.menuName
    menuGlobalStore.changeChooseFatherMenuName(chooseFatherMenuName.value)
  },
  { immediate: true }
)

watchEffect(() => {
  if (isOpenState) {
    openCollapse.value = uniq([...openCollapse.value])
    openCollapseSecond.value = uniq([...openCollapseSecond.value])
  } else {
    openCollapse.value = []
    openCollapseSecond.value = []
  }
})

watch(
  () => menuList.value,
  () => {
    openCollapse.value = menuList.value.map((item: MenuItemType) => item.menuName || '')
  },
  { immediate: true, deep: true }
)

onMounted(() => {
  const openMenuList: any = menuList.value.map((item: any) => {
    return item.menuName
  })
  const openSecondMenuList: any = flatten(
    map(menuList.value, (item: any) => {
      return item.children
    })
  ).map((item: any) => {
    return item.menuName
  })
  openCollapseSecond.value = [...openSecondMenuList]
  openCollapse.value = [chooseFatherMenuName.value, ...openMenuList]
  changePageMenuWidth()
  window.addEventListener('resize', changePageMenuWidth)
  nextTick(() => {
    initSortable()
    setTimeout(() => {
      // 恢复菜单滚动位置
      if (route?.params?.entityId && route?.name !== 'project-settings') {
        const elCollapseItem = menuContentRef.value?.querySelectorAll('.el-collapse-item')
        elCollapseItem?.forEach((item: any) => {
          if (item.attributes['data-entityId']?.value === route?.params?.entityId) {
            const { offsetTop } = item
            menuScrollbarRef.value?.setScrollTop(offsetTop)
          }
        })
      }
      if (route?.name === 'project-settings') {
        const elCollapseItem = menuContentRef.value?.querySelectorAll(
          '.is-basic,.elv-layout-menu-main-nav-box__settings'
        )
        elCollapseItem?.forEach((item: any) => {
          if (item.attributes['data-projectId']?.value === route?.params?.projectId) {
            const { offsetTop } = item
            menuScrollbarRef.value?.setScrollTop(item.className.includes('is-basic') ? offsetTop : offsetTop - 200)
          }
        })
      }
    }, 500)
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', changePageMenuWidth)
  emitter.off('onPushOpenCollapse', onPushOpenCollapse)
})
</script>
<style lang="scss" scoped>
.elv-layout-main-menu-container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  .elv-layout-main-menu-title-content {
    height: 32px;
    display: flex;
    box-sizing: border-box;
    align-items: center;

    &:hover {
      .elv-layout-main-menu-title-content__title {
        color: $elv-color-2D62EB;
      }
    }

    .elv-layout-main-menu-title-content__icon {
      margin-left: 16px;
      margin-right: 8px;
      height: 24px;
      display: flex;
      align-items: center;
    }

    .elv-layout-main-menu-title-content__title {
      height: 24px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 24px;
      color: #0e0f11;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 115px;
      text-align: left;
    }
  }

  .elv-layout-main-menu-children {
    &:first-of-type .elv-layout-main-menu-children-title-content {
      border: 0px;
      margin-top: 0px;
    }

    .elv-layout-main-menu-children-title-content {
      margin-left: 16px;
      margin-right: 10px;
      height: 32px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      border-top: 1px solid #edf0f3;
      margin-top: 8px;

      &:hover {
        .elv-layout-main-menu-title-title-content__title {
          color: $elv-color-2D62EB;
        }
      }

      .elv-layout-main-menu-title-content__icon {
        margin-left: 16px;
        margin-right: 8px;
        height: 24px;
        display: flex;
        align-items: center;
      }

      .elv-layout-main-menu-title-content-right__icon {
        margin-right: 0px;
      }

      .elv-layout-main-menu-children-title-content__title {
        height: 24px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        color: #aaafb6;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 115px;
        padding-left: 24px;
        text-align: left;
      }
    }
  }

  .elv-layout-menu-main-nav-box__settings {
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #1e2024;
    cursor: pointer;
    padding: 8px 0;
    margin-left: 16px;
    margin-right: 10px;
    box-sizing: border-box;
    padding-left: 16px;
    border-top: 1px solid #edf0f3;

    > div {
      display: flex;
      align-items: center;
      width: fit-content;
      height: 23px;
      padding: 4px 8px 4px 8px;
      box-sizing: border-box;
    }

    &:hover {
      > div {
        background: $elv-color-E9EFFF;
        border-radius: 20px;
        color: $elv-color-0E1420;
      }
    }

    &.elv-layout-menu-main-nav-box__settings-choose {
      > div {
        background: $elv-color-E9EFFF;
        border-radius: 20px;
        color: $elv-color-0E1420;
      }
    }
  }

  .elv-layout-main-menu-nav-create {
    margin-left: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 154px;
    height: 200px;
    border: 1px dashed #dde1e6;
    border-radius: 3px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #838d95;
    cursor: pointer;

    p {
      display: flex;
      flex-direction: column;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      color: #838d95;

      span {
        font-family: 'Plus Jakarta Sans';
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 4px;
      }
    }

    &:hover {
      border: 1px dashed #5e85eb;
      color: #0e0f11;

      svg {
        fill: #0e0f11;
      }
    }

    svg {
      fill: #838d95;
      margin-right: 4px;
    }
  }

  .elv-layout-main-menu-nav-created {
    padding-left: 16px;
    margin-left: 16px;
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid #edf0f3;
    width: 138px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    height: 22px;
    color: #838d95;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 0px;
    cursor: pointer;

    &:hover {
      color: #0e0f11;

      svg {
        fill: #0e0f11;
      }
    }

    svg {
      fill: #838d95;
      margin-right: 4px;
    }
  }

  .elv-layout-main-menu-nav-box {
    ul {
      margin: 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }

  .elv-layout-main-menu-title-content-right__icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .elv-layout-main-menu-title-content-right__icon--active {
    transform: rotate(180deg);
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="scss">
.elv-layout-main-menu-nav-box-main-tip {
  padding: 16px;
}

.elv-layout-main-menu-container {
  .el-collapse {
    border-bottom: 0px;
    border-top: 0px;
  }

  .el-collapse-item {
    &.is-disabled {
      .el-collapse-item__header {
        cursor: pointer;
      }
    }
  }

  .el-collapse-item__header {
    border: none;
    height: auto;
    background: $elv-color-F9FAFB;
  }

  .el-collapse-item__wrap {
    border: none;
    background: $elv-color-F9FAFB;
  }

  .el-collapse-item__content {
    padding-bottom: 0;
  }

  .el-collapse-item__arrow {
    margin: 10px 16px 0 0;
    display: none;
  }

  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

.elv-message-created-free-project-box {
  width: 590px;

  .el-dialog__body {
    padding: 10px 45px;
  }

  .elv-message-created-free-project-box-content {
    line-height: 45px;
    text-align: center;
    margin-bottom: 10px;
    white-space: pre;
  }
}
</style>
