<template>
  <el-dialog
    v-model="showAccountSettingDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-account-settings-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-account-settings-dialog-header__title">
        {{ t('button.accountSettings') }}
      </h4>
    </template>

    <el-form :model="accountSettingsForm" label-position="top">
      <el-form-item :label="t('common.name')" prop="name">
        <div class="elv-account-settings-form-item__text">{{ user?.name }}</div>
      </el-form-item>
      <el-form-item :label="t('common.email')" prop="email">
        <div class="elv-account-settings-form-item__text">{{ user?.email }}</div>
      </el-form-item>
      <el-form-item :label="t('common.password')" prop="password">
        <div class="elv-account-settings-form-item__text">
          <p @click="onChangePassword">{{ t('button.changePassword') }}</p>
        </div>
      </el-form-item>
      <el-form-item :label="t('title.twoFactorAuthentication')" prop="authenticator">
        <div class="elv-account-settings-form-item__text">
          {{ user?.twoFactorAuth ? t('title.authenticatorEnabled') : t('title.authenticatorDisabled') }}
          <div class="elv-account-settings-form-item__operating">
            <span v-if="user?.twoFactorAuth" @click="onBackupCodes">{{ t('title.backupCodes') }}</span>
            <span @click="onChangeAuthenticatorStatus">{{
              user?.twoFactorAuth ? t('button.disable') : t('button.setupNow')
            }}</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item :label="t('common.language')" prop="language">
        <el-select v-model="accountSettingsForm.language">
          <el-option label="English" value="EN_US" />
          <el-option label="简体中文" value="ZH_CN" />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <elv-button height="44" width="100" round type="primary" :loading="saveLoading" @click="onSaveSettings">{{
        t('button.save')
      }}</elv-button>
    </template>
  </el-dialog>

  <PasswordDialog ref="passwordDialogRef" />

  <BackupCodesDialog ref="backupCodesDialogRef" />

  <DisableAuthenticationDialog ref="disableAuthenticationDialogRef" />

  <SetupAuthenticationDialog ref="setupAuthenticationDialogRef" />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import UserApi from '@/api/UserApi'
import { ElMessage } from 'element-plus'
import PasswordDialog from './PasswordDialog.vue'
import BackupCodesDialog from './BackupCodesDialog.vue'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import SetupAuthenticationDialog from './SetupAuthenticationDialog.vue'
import DisableAuthenticationDialog from './DisableAuthenticationDialog.vue'

const { t, locale } = useI18n()
const userGlobalStore = useUserGlobalStore()
const { user } = storeToRefs(userGlobalStore)

const saveLoading = ref(false)
const showAccountSettingDialog = ref(true)
const passwordDialogRef = useTemplateRef('passwordDialogRef')
const backupCodesDialogRef = useTemplateRef('backupCodesDialogRef')
const setupAuthenticationDialogRef = useTemplateRef('setupAuthenticationDialogRef')
const disableAuthenticationDialogRef = useTemplateRef('disableAuthenticationDialogRef')

const accountSettingsForm = reactive({
  language: 'EN_US'
})

/**
 * @description: 切换弹窗展示
 * @return {*}
 */
const onCheckAccountSettingsDialog = () => {
  showAccountSettingDialog.value = !showAccountSettingDialog.value
}

/**
 * @description: 展示账户密码设置弹窗
 * @return {*}
 */
const onChangePassword = () => {
  onCheckAccountSettingsDialog()
  passwordDialogRef.value?.onCheckChangePasswordsDialog()
}

/**
 * @description: 展示备份码弹窗
 * @return {*}
 */
const onBackupCodes = () => {
  onCheckAccountSettingsDialog()
  backupCodesDialogRef.value?.onCheckBackupCodesDialog()
}

/**
 * @description: 展示设置认证弹窗
 * @return {*}
 */
const onChangeAuthenticatorStatus = () => {
  onCheckAccountSettingsDialog()
  if (user.value?.twoFactorAuth) {
    disableAuthenticationDialogRef.value?.onCheckDisableAuthenticationDialog()
  } else {
    setupAuthenticationDialogRef.value?.onCheckSetupAuthenticationDialog()
  }
}

/**
 * @description: 关闭弹窗
 * @return {*}
 */
const onCloseDialog = () => {}

/**
 * @description: 保存设置
 * @return {*}
 */
const onSaveSettings = async () => {
  try {
    saveLoading.value = true
    await UserApi.saveUserAccountSetting({
      language: accountSettingsForm.language
    })
    locale.value = accountSettingsForm.language === 'EN_US' ? 'en' : 'zh'
    localStorage.setItem('language', accountSettingsForm.language === 'EN_US' ? 'en' : 'zh')
    ElMessage.success(t('message.saveSuccess'))
    onCheckAccountSettingsDialog()
    userGlobalStore.userInit()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

defineExpose({ onCheckAccountSettingsDialog, showAccountSettingDialog })

watch(
  () => showAccountSettingDialog.value,
  async () => {
    if (showAccountSettingDialog.value) {
      accountSettingsForm.language = user.value?.language ?? 'EN_US'
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-account-settings-dialog {
  width: 500px;
  min-height: 565px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-settings-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .el-input {
      width: 452px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;
      width: 452px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }

      .elv-account-settings-form-item__text {
        border-radius: 4px;
        background: #f9fafb;
        height: 44px;
        width: 100%;
        padding: 8px 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        color: #000;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        position: relative;

        p,
        span {
          color: #5e85eb;
          cursor: pointer;
        }

        .elv-account-settings-form-item__operating {
          position: absolute;
          right: 0px;
          display: flex;
          align-items: center;

          span {
            margin-right: 12px;
          }
        }
      }
    }

    .el-select {
      &:hover:not(.el-select--disabled) .el-input__wrapper,
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-accounts-dialog-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }
  }
}
</style>
